import {
  SystemProps,
  Flex,
  Box,
  css,
  Stack,
  Grid,
  Text,
  Icon,
} from "@storyofams/react-ui";

import { chunk } from "lodash";
import Image from "next/image";
import { ArrowRight, NhArrowRight } from "~components/common/Icons";
import { Heading } from "../../Heading";
import { Link } from "../../Link";
import { HoverArea, ScaleOnHover } from "../../ScaleOnHover";
import { Slider } from "../../Slider";

type CategoriesProps = {
  first?: boolean;
  block?: boolean;
  content: {
    title?: string;
    categories?: any;
  };
} & SystemProps;

export const Categories = ({
  content,
  first,
  block,
  ...props
}: CategoriesProps) => {
  const chunks = chunk(content?.categories, 4);
  return (
    <>
      <Box
        display={["none", "flex"]}
        flexDirection="column"
        textAlign="center"
        css={css({
          width: "100%",
          ".swiper-slide": {
            width: ["136px", "282px"],
          },
          ".swiper-button-next, .swiper-button-prev": {
            display: "none",
          },
        })}
        {...props}
      >
        {!!content?.title && (
          <Heading
            width="260px"
            as={first ? "h1" : "h2"}
            variant="h3"
            fontWeight="bold"
            mb={[4, 5]}
          >
            {content?.title}
          </Heading>
        )}
        <Grid rowSize={[2, 3, 3, 4]} columnGap={3} rowGap={3}>
          {content?.categories[0]?.title &&
            content?.categories
              ?.filter(Boolean)
              ?.map(({ id, title, image, handle }) => (
                <Category
                  key={id}
                  title={title}
                  image={image}
                  handle={handle}
                />
              ))}
        </Grid>
      </Box>
      <Box
        css={css({
          ".swiper-slide": {
            width: "306px",
          },
        })}
        display={["block", "none"]}
        width={"100%"}
      >
        <Slider isNavigationCentered perPage={1} spaceBetween={"16px"}>
          {chunks &&
            chunks.length &&
            chunks?.map((array, i) => (
              <Box key={i}>
                <Grid rowSize={2} columnGap={2} rowGap={2}>
                  {array.map(({ id, title, image, handle }) => (
                    <Box key={id}>
                      <Category title={title} image={image} handle={handle} />
                    </Box>
                  ))}
                </Grid>
              </Box>
            ))}
        </Slider>
      </Box>
    </>
  );
};
const Category = ({ handle, title, image }) => {
  return (
    <Link href={`/collections/${handle}`} key={handle}>
      <Box cursor={"pointer"} width={"100%"} as="a">
        <Box
          p={["8px", "8px 16px"]}
          height={["122px", "100px"]}
          display="flex"
          flexDirection={["column", "row"]}
          alignItems="center"
          borderRadius={"8px"}
          backgroundColor={"#F4F3F0"}
          justifyContent={["center", "space-between"]}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={["column", "row"]}
            flexShrink={"0 !important"}
          >
            <Box
              borderRadius={"full"}
              overflow={"hidden"}
              position={"relative"}
              border="1px solid #E2DFDF"
              mr={[0, 2]}
              width={[54, 82]}
              height={[54, 82]}
              mb={["4px", 0]}
            >
              <Image
                objectFit="contain"
                quality={90}
                layout="fill"
                src={image?.originalSrc}
                alt={image?.altText || title}
              />
            </Box>
            <Text
              fontFamily={"DINPro"}
              fontWeight={700}
              maxWidth={["100%", "143px"]}
              fontSize={["16px", "20px"]}
              textAlign={"start"}
              lineHeight={["22.4px", "26px"]}
              mb={["4px", 0]}
            >
              {title}
            </Text>
          </Box>
          <Icon fontSize={"21px"} ml={[0, 2]} icon={NhArrowRight}></Icon>
        </Box>
      </Box>
    </Link>
  );
};
